export enum Color {
    VIOLET = "#855CF8",
    ORANGE = "#F26B2C",
    GREEN = "#2DAF2D",
    BLUE = "#455A64",
    PINK = "#DD408F",
}

export enum BackgroundColor {
    VIOLET = "#F1E6FF", // 연한 보라색
    ORANGE = "#FFE5D1", // 연한 오렌지색
    GREEN = "#F0FFF0", // 연한 초록색
    BLUE = "#E6F5FD", // 연한 파란색
    PINK = "#FFE6F2", // 연한 분홍색
}
